import React, {useRef, useEffect} from 'react'
import {Link} from "gatsby"

import Img from "../image"
import Headline from "../GeneralHeadline/GeneralHeadline"
import CardBtn from "../SpecialButton/SpecialButton"

import "./GeneralCard.scss"

const GeneralCard = ({data, gsap, ScrollTrigger}) => {
    let imgOne = useRef();
    let imgTwo = useRef();
    let imgThree = useRef();
    let imgFour = useRef();

    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        gsap.to([imgOne], {
          duration: 1,
          y: "-6vw",
          scrollTrigger: {
            trigger: imgOne,
            toggleActions: "restart pause reverse pause",
            scrub: 1,
          },
        })
    
        gsap.to([imgTwo], {
          duration: 1,
          y: "-10vw",
          scrollTrigger: {
            trigger: imgOne,
            toggleActions: "restart pause reverse pause",
            scrub: 1,
          },
        })
    
        gsap.to([imgThree], {
          duration: 1,
          y: "-20vw",
          scrollTrigger: {
            trigger: imgOne,
            toggleActions: "restart pause reverse pause",
            scrub: 1,
          },
        })
    
        gsap.to([imgFour], {
          duration: 1,
          y: "-5vw",
          scrollTrigger: {
            trigger: imgOne,
            toggleActions: "restart pause reverse pause",
            scrub: 1,
          },
        })
      })

    const headlineData = data.headline.split("/")

    return (
        <div className={`content-card card-${data.id}`}>  
           <div className={`headline-container`}>
              {headlineData.map((line, i) => (
                <Headline key={i} line={line} id="sub" />
              ))}
           </div>
           <div className="img-container">
                <div ref={el => imgOne = el} className="img-content-wrapper">
                    <Img image={data.img1} />
                </div>

                <div ref={el => imgTwo = el} className="img-content-wrapper">
                    <Img image={data.img2} />
                </div>

                <div ref={el => imgThree = el} className="img-content-wrapper">
                    <Img image={data.img3} />
                </div>

                <div ref={el => imgFour = el} className="img-content-wrapper">
                    <Img image={data.img4} />
                </div>
           </div>
                <div className="card-button-container">
                  <Link to={`/${data.slug}`}>
                      <CardBtn type="exp-/lore" />
                  </Link>
                </div>
        </div>
    )
}

export default GeneralCard
