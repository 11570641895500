import React from 'react'
import Img from "../image"

import "./BackgroundElement.scss"

const BackgroundElement = () => {
    return (
        <div className="background-element-wrapper">
            <Img image="background-element" />
        </div>
    )
}

export default BackgroundElement
