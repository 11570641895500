import React from 'react'

import "./SpecialButton.scss"

const SpecialButton = ({type}) => {

    const buttonText = type.split("/")

    return (
        <div className="special-button-wrapper">
            <h3>
                {buttonText.map((line, i) => (
                    <span key={i}>{line}</span>
                ))}
            </h3>
        </div>
    )
}

export default SpecialButton
