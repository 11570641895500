import React from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SEO from "../components/seo"



import Create from "../images/create-element.svg"

import Layout from "../components/layout"
import Card from "../components/GeneralCard/GeneralCard";
import Headline from "../components/GeneralHeadline/GeneralHeadline"
import BackgroundElement from "../components/BackgroundElement/BackgroundElement"

const data = [
    {id: 1, headline: "we are a hub/of creative/force", img1: "business-card", img2: "notebook", img3: "mobile-design", img4: "grid", slug: "concept"},
    {id: 2, headline: "what we're/drivin by", img1: "painting", img2: "code-art", img3: "grtypographyid", img4: "abstract", slug: "#"},
    {id: 3, headline: "behind/the/curtains", img1: "moritz", img2: "kristina", img3: "nikita", slug: "team"}
]

const headLineData = "web aplications/design/branding/communication";

const headline = headLineData.split("/")

const Home = () => {
    return (
        <Layout site="layout-container-home" footer="home-footer" menuBtn="menu-home">
            <SEO title="Home" />
            <div className="green-circle"></div>
            <div className="home-content-container">
                <div className="main-headline-container">
                    {
                        headline.map((line, i) => (
                            <Headline key={i} line={line} id="main" gsap={gsap} />
                            )) 
                        }
                </div>
                <img className="create-flag" src={Create} alt="create-flag-hend-und-fliege" />
                {data.map(card => (
                    <Card key={card.id} data={card} gsap={gsap} ScrollTrigger={ScrollTrigger} />
                ))}
            </div>
            <div className="background-element-container-home">
                <BackgroundElement />
            </div>
        </Layout>
    )
}

export default Home

